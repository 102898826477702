<template>
  <div class="add-entity-block-header"
       v-click-outside="outside"
       :class="classes">

    <template v-if="isMobile">
      <div class="title-mobile-block">
        <h1 class="page-title">
          {{ headerTitle }}
        </h1>

        <button type="button"
                @click="addAppBtnClicked"
                :class="{active: showAnimation}"
                class="add-entity-block-btn">
          <span class="text">Add new</span>
          <svg-icon :icon="showAppDetails ? 'arrow-left' : 'arrow-right'"
                    class="add-btn"/>
        </button>
      </div>

      <div class="app-adding-filters"
           v-if="showAppDetails">

        <div class="country-select sub-select">
          <custom-select-search @add-app-search-handler="addAppSearchCountryHandler"
                                @country-selected="countrySelectHandler"
                                no-active-border
                                :min-result-length="0"
                                :min-input-length="0"
                                :initial-data="countryInitial"
                                @dropdown-closed="dropdownClosed"
                                :search-results="searchCountryResults">
            <template v-slot:header>Select country</template>
          </custom-select-search>
        </div>

        <div class="platform-switch">
          <div class="platform-item"
               :class="{active: currentStore === 'APP_STORE'}"
               @click="currentStoreChanged('APP_STORE')">
            <img src="@/assets/images/icons/app_store.png"
                 alt="Store icon"
                 class="store-icon"
                 width="20"
                 height="20">
          </div>
          <div class="platform-item"
               @click="currentStoreChanged('GOOGLE_PLAY')"
               :class="{active: currentStore === 'GOOGLE_PLAY'}">
            <img src="@/assets/images/icons/google_play.png"
                 alt="Store icon"
                 class="store-icon"
                 width="18"
                 height="20">
          </div>
        </div>

        <div class="app-search-form">
          <template v-if="currentStore">
            <basic-search-input placeholder="Search by Name, ID, URL"
                                @search-input-changed="addAppSearchHandler"
                                :search-results="searchResults"
                                :is-focused-initially="true"
                                :hide-results-block="true"
                                class="add-app-country-search"
                                :input-disabled="!addedCountry?.code">
              <template v-slot:search-icon>
                <div class="preloader-wrap search-preloader"
                     v-if="searchIsLoading">
                  <preloader-table-small loader-size="20px"></preloader-table-small>
                </div>
                <svg-icon class="search-icon"
                          icon="search-solid"
                          @click="searchBtnClicked"
                          v-else/>
              </template>

              <template v-slot:alternative-results-block>
                <div class="results-block-wrap"
                     v-if="showSearchResults && !searchIsLoading && currSearchInput !== ''">
                  <div class="results-block">
                    <div class="result-item add-item-block"
                         v-if="searchResults?.result?.length > 0"
                         v-for="result in searchResults.result"
                         v-on="!isIncluded(result?.id) ? {click: () => addAppHandler(result)}: {}">

                      <template v-if="result?.logo">
                        <div class="product-logo">
                          <app-image
                                :width="22"
                                :height="22"
                                alt="App image"
                                :lazy-src="result?.logo"
                          />
                        </div>
                      </template>
                      <template v-else>
                        <div class="empty-logo"></div>
                      </template>

                      <div class="product-name">{{ result?.title ?? '' }}</div>

                      <div class="add-app-button">
                        <svg-icon v-if="isIncluded(result?.id)"
                                  class="included"
                                  icon="check-solid"/>
                        <svg-icon v-else
                                  icon="plus"/>
                      </div>
                    </div>

                    <div v-else
                         class="no-results">
                      We couldn't find what you are looking for
                    </div>

                    <template
                          v-if="searchResults?.result?.length > 0 && JSON.stringify(secondSearchResults) === '{}'">
                      <div class="wait-next-request">
                        🕓 For more complete search results, wait a few more seconds...
                      </div>
                    </template>
                    <template v-if="searchResults?.result?.length > 0 && secondSearchResults?.result?.length > 0">
                      <div class="result-item add-item-block"
                           v-for="result in secondSearchResults.result"
                           v-on="!isIncluded(result?.id) ? {click: () => addAppHandler(result)}: {}">

                        <template v-if="result?.logo">
                          <div class="product-logo">
                            <app-image
                                  :width="22"
                                  :height="22"
                                  alt="App image"
                                  :lazy-src="result?.logo"
                            />
                          </div>
                        </template>
                        <template v-else>
                          <div class="empty-logo"></div>
                        </template>

                        <div class="product-name">{{ result?.title ?? '' }}</div>

                        <div class="add-app-button">
                          <svg-icon v-if="isIncluded(result?.id)"
                                    class="included"
                                    icon="check-solid"/>
                          <svg-icon v-else
                                    icon="plus"/>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </basic-search-input>
          </template>
          <template v-else>
            <input type="text"
                   placeholder="Search by Name, ID, URL"
                   class="disabled-input"
                   title="Choose store!"
                   disabled>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="!isTablet">
        <h1 class="page-title">
          {{ headerTitle }}
        </h1>
      </template>

      <div class="add-app-block">
        <button type="button"
                @click="addAppBtnClicked"
                :class="{active: showAnimation}"
                class="add-entity-block-btn">
          <span class="text">Add new</span>
          <svg-icon :icon="showAppDetails ? 'arrow-left' : 'arrow-right'"
                    class="add-btn"/>
        </button>

        <div class="app-adding-filters"
             v-if="showAppDetails">

          <div class="country-select sub-select">
            <custom-select-search @add-app-search-handler="addAppSearchCountryHandler"
                                  @country-selected="countrySelectHandler"
                                  no-active-border
                                  :min-result-length="0"
                                  :min-input-length="0"
                                  :initial-data="countryInitial"
                                  @dropdown-closed="dropdownClosed"
                                  :search-results="searchCountryResults">
              <template v-slot:header>Select country</template>
            </custom-select-search>
          </div>

          <div class="platform-switch">
            <div class="platform-item"
                 :class="{active: currentStore === 'APP_STORE'}"
                 @click="currentStoreChanged('APP_STORE')">
              <img src="@/assets/images/icons/app_store.png"
                   alt="Store icon"
                   class="store-icon"
                   width="20"
                   height="20">
            </div>
            <div class="platform-item"
                 @click="currentStoreChanged('GOOGLE_PLAY')"
                 :class="{active: currentStore === 'GOOGLE_PLAY'}">
              <img src="@/assets/images/icons/google_play.png"
                   alt="Store icon"
                   class="store-icon"
                   width="18"
                   height="20">
            </div>
          </div>

          <div class="app-search-form">
            <template v-if="currentStore">
              <basic-search-input placeholder="Search by Name, ID, URL"
                                  @search-input-changed="addAppSearchHandler"
                                  :search-results="searchResults"
                                  :is-focused-initially="true"
                                  :hide-results-block="true"
                                  class="add-app-country-search"
                                  :input-disabled="!addedCountry?.code">
                <template v-slot:search-icon>
                  <div class="preloader-wrap search-preloader"
                       v-if="searchIsLoading">
                    <preloader-table-small loader-size="20px"></preloader-table-small>
                  </div>
                  <svg-icon class="search-icon"
                            icon="search-solid"
                            @click="searchBtnClicked"
                            v-else/>
                </template>

                <template v-slot:alternative-results-block>
                  <div class="results-block-wrap"
                       v-if="showSearchResults && !searchIsLoading && currSearchInput !== ''">
                    <div class="results-block">
                      <div class="result-item add-item-block"
                           v-if="searchResults?.result?.length > 0"
                           v-for="result in searchResults.result"
                           v-on="!isIncluded(result?.id) ? {click: () => addAppHandler(result)}: {}">

                        <template v-if="result?.logo">
                          <div class="product-logo">
                            <img :width="22"
                                  :height="22"
                                  alt="App image"
                                  :src="result?.logo"
                            />
                          </div>
                        </template>
                        <template v-else>
                          <div class="empty-logo"></div>
                        </template>

                        <div class="product-name">{{ result?.title ?? '' }}</div>

                        <div class="add-app-button">
                          <svg-icon v-if="isIncluded(result?.id)"
                                    class="included"
                                    icon="check-solid"/>
                          <svg-icon v-else
                                    icon="plus"/>
                        </div>
                      </div>

                      <div v-else
                           class="no-results">
                        We couldn't find what you are looking for
                      </div>

                      <template
                            v-if="searchResults?.result?.length > 0 && JSON.stringify(secondSearchResults) === '{}'">
                        <div class="wait-next-request">
                          🕓 For more complete search results, wait a few more seconds...
                        </div>
                      </template>


                      <template v-if="searchResults?.result?.length > 0 && secondSearchResults?.result?.length > 0">
                        <div class="result-item add-item-block"
                             v-for="result in secondSearchResults.result"
                             v-on="!isIncluded(result?.id) ? {click: () => addAppHandler(result)}: {}">

                          <template v-if="result?.logo">
                            <div class="product-logo">
                              <app-image
                                    :width="22"
                                    :height="22"
                                    alt="App image"
                                    :lazy-src="result?.logo"
                              />
                            </div>
                          </template>
                          <template v-else>
                            <div class="empty-logo"></div>
                          </template>

                          <div class="product-name">{{ result?.title ?? '' }}</div>

                          <div class="add-app-button">
                            <svg-icon v-if="isIncluded(result?.id)"
                                      class="included"
                                      icon="check-solid"/>
                            <svg-icon v-else
                                      icon="plus"/>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </basic-search-input>
            </template>
            <template v-else>
              <input type="text"
                     placeholder="Search by Name, ID, URL"
                     class="disabled-input"
                     title="Choose store!"
                     disabled>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BasicSearchInput from '@/components/UI/BasicSearchInput/index';
import CustomSelectWithSearch from '@/components/Forms/CustomSelectWithSearch/index';
import Accordion from '@/components/UI/Accordion/index';
import {httpRequest} from "@/api";
import {cropImage, sanitizeString} from "@/utils/utils";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import {mapGetters} from "vuex";

export default {
  name: "AddEntityBlock",
  components: {
    'preloader-table-small': PreloaderTableSmall,
    'basic-search-input': BasicSearchInput,
    'custom-accordion': Accordion,
    'custom-select-search': CustomSelectWithSearch,
  },
  props: {
    headerTitle: {
      type: String
    },
    classes: {
      type: String
    },
    appsList: {
      type: Object,
    },
    isMobile: {
      type: Boolean,
    },
    isTablet: {
      type: Boolean,
    },
  },
  emits: ['add-app-click-handler'],
  data() {
    return {
      showAppDetails: false,
      searchResults: {},
      secondSearchResults: {},
      searchCountryResults: {},
      addedCountry: {},
      countryInitial: null,
      fullCountriesList: [],
      currentStore: null,
      resultsCounter: 0,
      searchIsLoading: false,
      showSearchResults: false,
      currSearchInput: '',
      showAnimation: '',
    }
  },
  methods: {
    async addAppBtnClicked() {
      this.showAppDetails = !this.showAppDetails;

      if (this.showAppDetails === false) {
        this.currentStore = null;
        this.currSearchInput = null;
        this.searchResults = null;
        this.secondSearchResults = null;
        // this.searchIsLoading = false;
      } else {
        this.searchIsLoading = false;
        this.addedCountry = localStorage.getItem('addedCountry') ? JSON.parse(localStorage.getItem('addedCountry')) : {
          code: 'US',
          name: 'United States'
        };
        if (this.addedCountry) {
          this.countryInitial = {
            code: this.addedCountry?.code,
            name: this.addedCountry?.name,
          };
        }

        await this.addAppSearchCountryHandler();
      }
    },
    async addAppSearchHandler(value) {
      if (value?.length > 2) {
        this.currSearchInput = value;
        this.showSearchResults = false;
        this.searchIsLoading = true;
        const url = process.env.VUE_APP_API_URL
            + this.$ApiUrls.myApps.LIVE_SEARCH_APPLICATIONS
            + '?country_code=' + this.addedCountry?.code
            + '&query=' + value
            + '&store_key=' + this.currentStore
            + '&page=1&limit=10';
        const result = await httpRequest('GET', url);

        let searchResults;

        if (result?.result) {
          searchResults = {
            results: result?.result?.map(resultItem => {
              let logo = null;
              if (resultItem?.logo) {
                logo = resultItem?.logo;
              } else if (resultItem?.icon) {
                logo = resultItem?.icon;
              }
              logo = cropImage(logo, '22x22', resultItem?.store?.key);

              return {
                id: resultItem?.id ?? null,
                store_key: resultItem?.store?.key ?? null,
                logo,
                link: resultItem?.link ?? null,
                title: resultItem?.title ? sanitizeString(resultItem?.title) : null,
              }
            })
          };
        } else {
          searchResults = {
            results: []
          }
        }

        this.searchResults = {result: searchResults?.results?.filter(item => item !== null)};
        this.searchIsLoading = false;
        this.showSearchResults = true;

        setTimeout(async () => {
          await this.secondSearchRequest();
        }, 500);
      } else {
        this.searchResults = {};
      }
    },
    async secondSearchRequest() {
      if (!this.currSearchInput) {
        return;
      }

      const url = process.env.VUE_APP_API_URL
          + this.$ApiUrls.myApps.LIVE_SEARCH_APPLICATIONS
          + '?country_code=' + this.addedCountry?.code
          + '&query=' + this.currSearchInput
          + '&store_key=' + this.currentStore
          + '&page=2&limit=10';
      const result = await httpRequest('GET', url);

      let searchResults;

      if (result?.result) {
        searchResults = {
          results: result?.result?.map(resultItem => {
            let logo = null;
            if (resultItem?.logo) {
              logo = resultItem?.logo;
            } else if (resultItem?.icon) {
              logo = resultItem?.icon;
            }
            logo = cropImage(logo, '22x22', resultItem?.store?.key);

            return {
              id: resultItem?.id ?? null,
              store_key: resultItem?.store?.key ?? null,
              logo,
              link: resultItem?.link ?? null,
              title: resultItem?.title ? sanitizeString(resultItem?.title) : null,
            }
          })
        };
      } else {
        searchResults = {
          results: []
        }
      }

      this.secondSearchResults = {result: searchResults?.results?.filter(item => item !== null)};
    },
    async searchBtnClicked() {
      if (!this.currSearchInput) {
        this.searchIsLoading = false;
        return;
      }

      this.searchIsLoading = true;
      this.showSearchResults = false;

      const url = process.env.VUE_APP_API_URL
          + this.$ApiUrls.myApps.LIVE_SEARCH_APPLICATIONS
          + '?country_code=' + this.addedCountry?.code
          + '&query=' + this.currSearchInput
          + '&store_key=' + this.currentStore
          + '&page=1&limit=10';
      const result = await httpRequest('GET', url);

      let searchResults;

      if (result?.result) {
        searchResults = {
          results: result?.result?.map(resultItem => {
            let logo = null;
            if (resultItem?.logo) {
              logo = resultItem?.logo;
            } else if (resultItem?.icon) {
              logo = resultItem?.icon;
            }
            logo = cropImage(logo, '22x22', resultItem?.store?.key);

            return {
              id: resultItem?.id ?? null,
              store_key: resultItem?.store?.key ?? null,
              logo,
              link: resultItem?.link ?? null,
              title: resultItem?.title ? sanitizeString(resultItem?.title) : null,
            }
          })
        };
      } else {
        searchResults = {
          results: []
        }
      }

      this.searchResults = {result: searchResults?.results?.filter(item => item !== null)};

      this.searchIsLoading = false;
      this.showSearchResults = true;
      setTimeout(async () => {
        await this.secondSearchRequest();
      }, 500);
    },
    async addAppSearchCountryHandler(value) {
      const queryString = value?.length >= 0 ? '?query=' + value : null;
      if (queryString) {
        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES + queryString);
        const filteredCountries = result?.countries?.filter(country => {
          return country?.name?.toLowerCase()?.includes(value?.toLowerCase());
        });

        this.searchCountryResults = {result: filteredCountries?.filter(item => item?.code !== 'WORLDWIDE')};
      } else {
        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES);
        this.searchCountryResults = {result: result?.countries?.filter(item => item?.code !== 'WORLDWIDE')};
      }

      if (this.fullCountriesList?.length === 0) {
        this.fullCountriesList = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES_WITH_ID);
      }
    },
    async countrySelectHandler(value) {
      this.addedCountry = value;

      await this.addAppSearchCountryHandler('');
    },
    addAppHandler(value) {
      localStorage.setItem('addedCountry', JSON.stringify(this.addedCountry));
      this.resultsCounter++;
      this.showAppDetails = false;
      this.showSearchResults = false;

      this.currentStore = null;
      this.currSearchInput = null;
      this.searchResults = null;
      this.secondSearchResults = null;

      this.$emit('add-app-click-handler', {
        application_id: value?.id,
        country_code: this.addedCountry?.code,
        fullCountriesList: this.fullCountriesList
      });
    },
    isIncluded(id) {
      if (!this.appsList) {
        return false;
      }

      for (const key in this.appsList) {
        if (this.appsList[key]?.id === id) {
          return true;
        }
      }
    },
    async dropdownClosed() {
      await this.addAppSearchCountryHandler('');
    },
    async outside() {
      this.showSearchResults = false;
      await this.addAppSearchCountryHandler('');
    },
    currentStoreChanged(store) {
      this.currentStore = store;
      if (this.currSearchInput !== '') {
        this.searchBtnClicked();

        if (this.currSearchInput === null) {
          this.currSearchInput = '';
        }
      }
    },
  },
  mounted() {
    this.currentStore = null;
    this.currSearchInput = null;
    this.searchResults = null;
    this.secondSearchResults = null;
  },
  computed: {
    ...mapGetters([
      'callToAddAppCounter',
    ]),
  },
  watch: {
    callToAddAppCounter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showAnimation = true;

        setTimeout(() => {
          this.showAnimation = false;
        }, 500);
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>